import { APIs } from 'src/models/apis';
import { QueryKey } from 'src/models/query';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { useUserQuery } from 'src/modules/user/hooks/useUserQuery';
import { fetchEventsList } from 'src/modules/events/hooks/useEventsListQuery';
import { queryCommonOptions } from 'src/utils/queryCommonOptions';
import { Events } from 'src/graphql/schema.graphql';

export const fetchUserEventsList = async (apis: APIs) => {
  const directus = await apis.directus();

  const eventIds = await directus.api.userEvents.list();

  if (!eventIds || eventIds.length === 0) {
    return [];
  }

  return fetchEventsList(apis, { filters: { id: { _in: eventIds } } });
};

export const userEventsListQuery = (
  apis: APIs,
  enabled: boolean,
): UseQueryOptions<Events[] | null> => {
  return {
    ...queryCommonOptions.commonHandlers,
    ...queryCommonOptions.longStale,
    queryKey: QueryKey.UserEvents,
    queryFn: () => fetchUserEventsList(apis),
    enabled,
  };
};

export const useUserEventsListQuery = () => {
  const apis = useAPIs();
  const user = useUserQuery();

  return useQuery(userEventsListQuery(apis, !!user.data));
};
