import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { media } from 'src/styles/designs';
import { defaultTheme, fromTheme, redTheme } from 'src/styles/theme';
import { transparentize } from 'polished';
import { Theme } from 'vendor/mods/styled-components';
import { StyleProps } from '@summer/jst-react';
import { useRectMeasure } from 'src/hooks/useRectMeasure';
import { useWindowScroll } from 'src/hooks/useWindowScroll';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { TabsSwiper, Tab } from 'src/components/common/TabsSwiper';
import { StickyMenuContext } from 'src/components/common/StickyMenu/StickyMenuContext';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';

export interface StickyMenuProps<T extends string = string> {
  tabs?: Tab<T>[];
  activeTab?: T;
  bannerHeight?: number;
  transparentOnTop?: boolean;
  theme?: StyledComponentsTheme;
}

interface ContainerProps {
  $passedBanner?: boolean;
  $fixed?: boolean;
  $transparent?: boolean;
}

const height = {
  mobile: 42,
  desktop: 52,
} as const;

const Container = styled.div<ContainerProps>`
  position: ${({ $fixed }) => ($fixed ? 'fixed' : 'sticky')};
  top: calc(var(--header-fixed-height));

  ${({ $fixed }) =>
    $fixed &&
    css`
      right: calc(var(--scrollbar-padding) / 2);
    `};

  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 10;

  backdrop-filter: blur(8rpx);
  transform: translate3d(0, 0, 0);

  ${casePathEmptyString('$passedBanner')(css`
    .sticky-menu__navbar {
      background: rgba(255, 255, 255, 0.85);
    }
    ${media.w.lessThan.desktop} {
      .sticky-menu__navbar {
        background: ${fromTheme((theme) =>
          transparentize(0.15, theme.palette.background),
        )};
      }
      .sticky-menu__button {
        color: ${fromTheme((theme) => theme.text.palette.heavy)};
        border-color: ${fromTheme((theme) =>
          transparentize(0.15, theme.text.palette.heavy),
        )};
      }
    }
  `)}

  ${casePathEmptyString('$transparent')(css`
    backdrop-filter: blur(0px) !important;
    .sticky-menu__navbar {
      background: transparent !important;
    }
  `)}
`;

const Navbar = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 9px;
  background: transparent;
  transition: 0.25s;
  height: ${height.mobile}px;

  ${media.w.greaterThanOrEqual.desktop} {
    padding-top: 11px;
    padding-bottom: 13px;
    height: ${height.desktop}px;
  }
`;

const FixedNavbarSpaceReservation = styled.div`
  height: ${height.mobile}px;
  ${media.w.greaterThanOrEqual.desktop} {
    height: ${height.desktop}px;
  }
`;

export const StickyMenu = <T extends string>({
  tabs = [],
  theme,
  activeTab,
  bannerHeight,
  transparentOnTop,
  ...props
}: StickyMenuProps<T> & StyleProps) => {
  const { y } = useWindowScroll();
  const isDesktop = useMediaQuery(media.w.greaterThanOrEqual.desktop, true);

  const ctx = useContext(StickyMenuContext);

  const [containerRef, navHeight] = useRectMeasure(
    isDesktop ? height.desktop : height.mobile,
  );

  const passedBanner = bannerHeight == null || y >= bannerHeight - navHeight;

  const isFixed = !bannerHeight ? false : y > navHeight;

  const allTabs = [...tabs, ...ctx.registeredSections];

  if (allTabs.length < 2) {
    return null;
  }

  return (
    <Theme theme={theme || (passedBanner ? defaultTheme : redTheme)}>
      {isFixed && <FixedNavbarSpaceReservation />}

      <Container
        {...props}
        ref={containerRef}
        $fixed={isFixed}
        $passedBanner={passedBanner}
        $transparent={transparentOnTop ? y === 0 : false}
      >
        <Navbar className="sticky-menu__navbar">
          <TabsSwiper
            tabs={allTabs}
            activeTab={activeTab ?? (ctx.activeSection as T)}
            buttonClassName="sticky-menu__button"
          />
        </Navbar>
      </Container>
    </Theme>
  );
};
