import styled from 'styled-components';
import { TrackedLink } from 'src/components/TrackedLink';
import SwiperClass from 'swiper/types/swiper-class';
import { memo, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslate } from 'react-t7e';
import { ScrollToSectionOnClick } from 'src/components/common/ScrollToSectionOnClick';
import { TabButton } from 'src/components/common/Button/TabButton';
import { ConstraintContainer } from 'src/components/common/ConstraintContainer';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';

export type Tab<T extends string> = {
  id: T;
  label: string;
  link?: string;
};

export interface TabsSwiperProps<T extends string> {
  tabs: Tab<T>[];
  activeTab: T;
  scrollOffset?: number;
  buttonClassName?: string;
  setActiveTab?: (tab: T) => void;
}

const SwiperContainer = styled(ConstraintContainer)`
  .swiper-slide {
    min-width: min-content;
    width: auto;
  }
  .swiper-wrapper {
    gap: 20px;
    max-width: max-content;
    margin: 0 auto;
  }
`;

const TabsSwiperComponent = <T extends string>({
  tabs,
  activeTab,
  scrollOffset,
  buttonClassName,
  setActiveTab,
}: TabsSwiperProps<T>) => {
  const { _ } = useTranslate();

  const swiperRef = useRef<SwiperClass>();

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current?.slideTo(tabs.findIndex(({ id }) => id === activeTab));
    }
  }, [tabs, activeTab]);

  return (
    <SwiperContainer
      as={Swiper}
      slidesPerView="auto"
      threshold={10}
      onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
    >
      {tabs.map((tab) => (
        <SwiperSlide key={tab.id}>
          {tab.link ? (
            <TrackedLink href={tab.link} passHref>
              <TabButton
                className={buttonClassName}
                as="a"
                $active={activeTab === tab.id}
              >
                <Markdown>{_(tab.label)}</Markdown>
              </TabButton>
            </TrackedLink>
          ) : (
            <ScrollToSectionOnClick id={tab.id} offset={scrollOffset}>
              <TabButton
                $active={activeTab === tab.id}
                className={buttonClassName}
                onClick={() => setActiveTab?.(tab.id)}
              >
                <Markdown>{_(tab.label)}</Markdown>
              </TabButton>
            </ScrollToSectionOnClick>
          )}
        </SwiperSlide>
      ))}
    </SwiperContainer>
  );
};

export const TabsSwiper = memo(
  TabsSwiperComponent,
) as typeof TabsSwiperComponent;
