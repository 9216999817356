import { Events, Events_Filter } from 'src/graphql/schema.graphql';
import { eventsGqlFragment } from 'src/modules/events/graphql/events.fragment';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const eventsListGqlQuery: GqlFragment<
  { events: Events[] },
  { filters: Events_Filter; sort: string[] }
> = gql`
  ${eventsGqlFragment}
  query EventsList($filters: events_filter, $sort: [String]) {
    events(filter: $filters, sort: $sort, limit: -1) {
      ...events
    }
  }
`;
