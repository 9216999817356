import React, { useCallback, useMemo } from 'react';
import { Events } from 'src/graphql/schema.graphql';
import Countdown from 'react-countdown';
import { parseISO } from 'date-fns';
import { CountdownRendererFn } from 'react-countdown/dist/Countdown';
import styled from 'styled-components';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { media } from 'src/styles/designs';
import { StyleProps, useClientReady } from '@summer/jst-react';

export interface EventCountdownProps {
  event: Events;
  onComplete?: () => void;
}

const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: start;

  margin-top: 36px;
  grid-gap: 20px;
  grid-template-areas: 'title values';
  ${media.w.greaterThanOrEqual.desktop} {
    position: absolute;
    bottom: 25%;
    right: var(--constraint-container-right-inner-margin);
    transform: translateY(-25%);

    display: grid;
    ${dirRTL} {
      right: auto;
      left: var(--constraint-container-left-inner-margin);
    }
  }
`;

const Title = styled.span`
  grid-area: title;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
`;

const Values = styled.div`
  grid-area: values;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px;

  ${media.w.greaterThanOrEqual.desktop} {
    grid-gap: 16px;
  }
`;

const ValueRect = styled.div`
  border: 2rpx solid ${fromTheme((a) => a.text.palette.primary)};
  color: ${fromTheme((a) => a.text.palette.primary)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  ${media.w.greaterThanOrEqual.desktop} {
    width: 70px;
    height: 70px;
  }
`;

const Value = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
`;

export const EventCountdown = ({
  event,
  onComplete,
  ...props
}: EventCountdownProps & StyleProps) => {
  const isReady = useClientReady();
  const { _ } = useTranslate();

  const startDate = useMemo(() => parseISO(event.startDate), [event]);

  const countdownRenderer: CountdownRendererFn = useCallback(
    ({ days, hours, minutes, seconds, completed }) => {
      if (days >= 14 || completed) {
        return null;
      }

      return (
        <Container {...props}>
          <Title>{_('Starts in')}</Title>
          <Values>
            {days >= 1 && (
              <ValueRect>
                <Value>{days}</Value>
                <Label>{_('days')}</Label>
              </ValueRect>
            )}
            <ValueRect>
              <Value>{hours}</Value>
              <Label>{_('hours')}</Label>
            </ValueRect>
            <ValueRect>
              <Value>{minutes}</Value>
              <Label>{_('min')}</Label>
            </ValueRect>
            {days < 1 && (
              <ValueRect>
                <Value>{seconds}</Value>
                <Label>{_('sec')}</Label>
              </ValueRect>
            )}
          </Values>
        </Container>
      );
    },
    [_, props],
  );

  if (!isReady) {
    return null;
  }

  return (
    <Countdown
      date={startDate}
      renderer={countdownRenderer}
      onComplete={onComplete}
    />
  );
};
