import { APIs } from 'src/models/apis';
import { QueryKey } from 'src/models/query';
import { eventsListGqlQuery } from 'src/modules/events/graphql/events-list.query';
import { useQuery } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { Variables } from 'src/utils/gql/query';

export const fetchEventsList = async (
  apis: APIs,
  variables?: Variables<typeof eventsListGqlQuery>,
) => {
  const directus = await apis.directus();

  return directus
    .query(eventsListGqlQuery, {
      sort: ['sort'],
      ...variables,
    })
    .then((a) => a.events);
};

export const useEventsListQuery = () => {
  const apis = useAPIs();

  return useQuery(QueryKey.EventsList, () => fetchEventsList(apis));
};
