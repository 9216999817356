import {
  cloneElement,
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
} from 'react';
import { LayoutRegisterContext } from 'src/providers/LayoutRegisterProvider';
import { ResponsiveUnit } from 'src/components/common/ResponsiveUnit';

export const ScrollToSectionOnClick = <
  Props extends { onClick?: (e: MouseEvent) => void },
>({
  children,
  id,
  offset = 0,
  ...props
}: Partial<Props> & {
  children: ReactElement<Props>;
  id: string;
  offset?: number;
}) => {
  const layoutRegisterContext = useContext(LayoutRegisterContext);

  const scrollToSection = useCallback(
    (px: number) => {
      const section: HTMLElement | null = document?.querySelector(`#${id}`);

      if (section != null) {
        window.scrollTo({
          top:
            section.getBoundingClientRect().top +
            window.scrollY -
            offset * px -
            (layoutRegisterContext?.ui.height?.header.fixed ?? 0) * px,
          behavior: 'smooth',
        });
      }
    },
    [id, offset, layoutRegisterContext?.ui.height?.header.fixed],
  );

  const clickCallback = useCallback(
    (e: MouseEvent, px: number) => {
      children.props.onClick?.(e);
      props.onClick?.(e);
      scrollToSection(px);
    },
    [children.props, props, scrollToSection],
  );

  return (
    <ResponsiveUnit>
      {(px) =>
        cloneElement(children, {
          ...(props as any),
          onClick: (e: MouseEvent) => clickCallback(e, px),
        })
      }
    </ResponsiveUnit>
  );
};
