import React from 'react';
import Head from 'next/head';

export function MetaDescription(props: { children?: string }) {
  const description = props.children;

  return description ? (
    <Head>
      <meta key="description" name="description" content={description} />
      <meta key="og:description" name="og:description" content={description} />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      />
    </Head>
  ) : null;
}
