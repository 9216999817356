import { useAddUserEventMutation } from 'src/modules/events/hooks/useAddUserEventMutation';
import { useRemoveUserEventMutation } from 'src/modules/events/hooks/useRemoveUserEventMutation';
import { useUserEventsListQuery } from 'src/modules/events/hooks/useUserEventsListQuery';
import { anyQueryIsLoading } from 'src/utils/reactQuery';

export const useUserEvents = () => {
  const userEventsQuery = useUserEventsListQuery();

  const addUserEventMutation = useAddUserEventMutation();
  const removeUserEventMutation = useRemoveUserEventMutation();

  return {
    isLoading: anyQueryIsLoading(
      userEventsQuery,
      addUserEventMutation,
      removeUserEventMutation,
    ),
    events: userEventsQuery.data,
    enabled: !!userEventsQuery.data,
    add: addUserEventMutation.mutateAsync,
    remove: removeUserEventMutation.mutateAsync,
    checkUserEnrollment: (eventId: string) =>
      userEventsQuery.data?.some((e) => e.id === eventId),
  };
};
