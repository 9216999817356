import { uniqBy } from 'lodash';
import { sort } from 'rambda';
import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useState,
} from 'react';

type Section = {
  id: string;
  label: string;
  index?: number;
};

export interface StickyMenuContextProps {
  activeSection: string | null;
  registeredSections: Section[];
  registerSection: (section: Section) => void;
  handleActiveSectionChange: (value: string | null) => void;
}

export const StickyMenuContext = createContext<StickyMenuContextProps>({
  activeSection: null,
  registeredSections: [],
  registerSection: () => null,
  handleActiveSectionChange: () => null,
});

export const StickyMenuProvider: FC = ({ children }) => {
  const [registeredSections, setRegisteredSections] = useState<Section[]>([]);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const registerSection = useCallback(
    (section: Section) =>
      setRegisteredSections((state) =>
        sort(
          (a, b) => ((a.index ?? 0) > (b.index ?? 0) ? 1 : 0),
          uniqBy([...state, section], (s) => s.id),
        ),
      ),
    [setRegisteredSections],
  );

  const handleActiveSectionChange = useCallback(
    (value: string | null) => {
      setActiveSection(value);
    },
    [setActiveSection],
  );

  const value = useMemo(
    () => ({
      activeSection,
      registeredSections,
      registerSection,
      handleActiveSectionChange,
    }),
    [
      activeSection,
      registeredSections,
      handleActiveSectionChange,
      registerSection,
    ],
  );

  return (
    <StickyMenuContext.Provider value={value}>
      {children}
    </StickyMenuContext.Provider>
  );
};
