import { parseISO, isSameDay, isSameMonth } from 'date-fns';
import { useMemo } from 'react';

export const useIsMultidayEvent = <
  T extends {
    startDate: string;
    endDate: string;
  },
>(
  event: T,
) => {
  const [isMultiDayEvent, displayStartDateMonth] = useMemo(() => {
    const startDate = parseISO(event.startDate);
    const endDate = parseISO(event.endDate);

    return [!isSameDay(startDate, endDate), !isSameMonth(startDate, endDate)];
  }, [event]);

  return { isMultiDayEvent, displayStartDateMonth };
};
