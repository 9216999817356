import { useRef } from 'react';
import { useTranslate } from 'react-t7e';
import { ButtonProps, FullWidthButton } from 'src/components/common/Button';
import { AddIcon } from 'src/components/icons/AddIcon';
import { Close } from 'src/components/icons/Close';
import { Events } from 'src/graphql/schema.graphql';
import { useUserEvents } from 'src/modules/events/hooks/useUserEvents';
import { AnimationControllerImperativeAPI } from 'src/components/common/AnimationController';
import { withAnimationController } from 'src/components/common/Button/withAnimationController';

export interface UserEventsEventButtonProps extends ButtonProps {
  event: Events;
}

export const UserEventsEventButton = ({
  event,
  ...props
}: UserEventsEventButtonProps) => {
  const { _ } = useTranslate();

  const { add, remove, checkUserEnrollment, enabled } = useUserEvents();

  const isAddedToEvents = checkUserEnrollment(event.id);

  const animationControllerRef =
    useRef<AnimationControllerImperativeAPI | null>(null);

  const onClick = withAnimationController(animationControllerRef, () =>
    isAddedToEvents ? remove(event.id) : add(event.id),
  );

  if (!enabled) {
    return null;
  }

  return (
    <FullWidthButton
      icon={isAddedToEvents ? Close : AddIcon}
      iconProps={{ position: 'right', size: 12 }}
      onClick={onClick}
      stateControllerRef={animationControllerRef}
      {...props}
    >
      {isAddedToEvents ? _('Remove from my events') : _('Add to my events')}
    </FullWidthButton>
  );
};
