import { useMemo } from 'react';
import { Events } from 'src/graphql/schema.graphql';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';

export const useFlattenEvent = (event: Events) => {
  const translateFn = useTranslateItem();

  return useMemo(() => {
    const translatedEvent = translateFn(event);
    const translatedEventType =
      event.type != null ? translateFn(event.type) : null;

    return {
      ...translatedEventType,
      ...translatedEvent,
      id: translatedEvent.id,
      name: translatedEvent.name,
      description:
        translatedEvent.description || translatedEventType?.description || '',
      excerpt: translatedEvent.excerpt || translatedEventType?.excerpt || '',
      type: translatedEventType,
      tags: (event.tags?.length > 0
        ? event.tags
        : event.type?.tags?.length > 0
        ? event.type.tags
        : []
      ).map((a) => translateFn(a.tags_id)),
    };
  }, [event, translateFn]);
};
