import { transparentize } from 'polished';
import { CleanButton } from 'src/components/common/Button';
import { media } from 'src/styles/designs';
import { fromTheme } from 'src/styles/theme';
import styled, { css } from 'styled-components';

export const TabButton = styled(CleanButton)<{ $active?: boolean }>`
  && {
    display: flex;
    align-items: center;

    min-width: min-content;
    min-height: 26px;

    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    white-space: nowrap;

    transition: all 0.2s ease;

    color: ${fromTheme((theme) => theme.text.palette.primary)};
    border-bottom: 1rpx solid
      ${fromTheme((theme) => transparentize(0.6, theme.text.palette.primary))};

    ${media.w.greaterThanOrEqual.desktop} {
      font-size: 14px;
      padding-bottom: 6px;
    }

    br {
      display: none;
    }

    &:hover,
    &:focus {
      color: ${fromTheme((theme) => theme.text.palette.accent)};
      border-color: currentColor;
    }

    ${({ $active }) =>
      $active
        ? css`
            font-weight: 700;
            color: ${fromTheme(
              (theme) => theme.text.palette.accent,
            )} !important;
            border-color: ${fromTheme(
              (theme) => theme.text.palette.accent,
            )} !important;
            b,
            strong {
              font-weight: 700;
            }
          `
        : css`
            font-weight: 300;
            color: ${fromTheme((theme) => theme.text.palette.heavy)};
            b,
            strong {
              font-weight: 300;
            }
          `};
  }
`;

export const TabIconButton = styled(TabButton)`
  && {
    min-width: 24px;
    justify-content: center;
  }
`;
