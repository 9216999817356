import { useMutation, useQueryClient } from 'react-query';
import { useAPIs } from 'src/hooks/useAPIs';
import { QueryKey } from 'src/models/query';

export const useAddUserEventMutation = () => {
  const apis = useAPIs();
  const queryClient = useQueryClient();

  return useMutation(
    async (eventId: string) => {
      const directus = await apis.directus();

      return directus.api.userEvents.add(eventId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.UserEvents);
      },
    },
  );
};
