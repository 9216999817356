import { gql } from 'src/utils/gql/query';

export const eventsGqlFragment = gql`
  fragment events on events {
    id
    slug
    form
    reach
    startDate
    endDate
    background
    pastCtaLink
    upcomingCtaLink
    mailchimpTag
    logo {
      id
    }
    image {
      id
    }
    translations {
      howToParticipate
      upcomingCtaLabel
      description
      excerpt
      name
      languages_code {
        code
      }
    }
    tags {
      tags_id {
        id
        slug
        translations {
          name
          languages_code {
            code
          }
        }
      }
    }
    type {
      id
      background
      image {
        id
      }
      logo {
        id
      }
      translations {
        description
        excerpt
        name
        languages_code {
          code
        }
      }
      tags {
        tags_id {
          id
          slug
          translations {
            name
            languages_code {
              code
            }
          }
        }
      }
    }
  }
`;
